<!-- 首页 -->

<template>
	<div class="page_container" style="height:85vh">
		<a-row :gutter="24" type="flex" align="stretch" style="height: 100%; flex: 1">
			<a-col :span="24">
				<a-card :bordered="false" class="header-solid h-full" style="box-shadow: none" :bodyStyle="{ background:'radial-gradient(circle at 12% 55%, rgba(33, 150, 243, 0.15), hsla(0, 0%, 100%, 0) 25%), radial-gradient(circle at 85% 33%, rgba(108, 99, 255, 0.175), hsla(0, 0%, 100%, 0) 25%)',height: '100%'}">
					<a-col :span="6" style="height: 100%; padding: 0 6px;max-width: 380px;" v-if="qiyeban">
						<div class="dialog_box">
							<div class="head" @click="createDialog('create', '')">创建新对话</div>
							<div class="list">
								<div class="item" :class="{ active: item.id == dialogId }" v-for="(item, index) in dialogList" :key="'dialog_' + item.id" @click="dialogItem(item.id)">
									<div class="line1 c-line-1">{{ item.title }}</div>
									<div class="line2">
										<span>{{ item.createtime }}</span>
										<span>{{ item.msg_total }}条对话</span>
									</div>
									<div class="delete" @click.stop="deleteDialog(item.id)">
										<a-icon type="minus-circle" theme="filled" :style="{ fontSize: '24px', color: '#999', display: 'block' }" />
									</div>
									<div class="edit" @click.stop="createDialog('edit', item.id, item.title)">编辑</div>
								</div>
							</div>
							<div class="foot" v-if="dialogList.length">
								<span class="c-pointer" @click="clearAllChat">清空所有会话</span>
							</div>
							<a-modal title="会话标题" :visible="createModal" cancelText="取消" okText="确定" :confirm-loading="createLoading" @ok="createOkHandle" @cancel="createModal = false">
								<a-input v-model="createTitle" placeholder="请输入会话标题" allow-clear />
							</a-modal>
						</div>
					</a-col>
					<a-col :span="18" style="height: 100%; padding: 0 6px;">
						<div class="content_box">
							<div class="output" id="output">
								<div class="welcome" v-if="dialogId == 0" >
									<div class="head" v-if="qiyeban">
											<div class="title">此刻起，让AI改变你的生活！</div>
										</div>
										<div class="head" v-else>
												<div class="title">欢迎使用OS-AIGC（企业版）<span style="font-size: 12px;font-weight: 200px;float: right;">所属企业：{{userInfo.company.name}}</span></div>

											</div>
									<div class="classify_box" v-if="qiyeban && presetData.length>0">
										<div class="tabs">
											<div class="item" :class="{ active: presetIndex == index }" v-for="(item, index) in presetData" :key="'preset_' + index" @click=";(presetIndex = index), getPresetList()">{{ item.title }}</div>

										</div>

										<div class="lists scroll-display-none" v-if="presetData.length>0 && presetData[presetIndex].data.length">
											<waterfall :col="3" :gutterWidth="4" :data="presetData[presetIndex].data">
												<div class="item" v-for="(item, index) in presetData[presetIndex].data" :key="'preset_item_' + index" @click="promptInsertHtml(item.prompt), presetClicks(1, item.id, index)">
													<div class="title">{{ item.title }}</div>
													<div class="text">{{ item.desc }}</div>
												</div>


											</waterfall>

										</div>
									</div>
									<div class="classify_box" v-else>
										<div class="tabs">
											<div class="item" :class="{ active: presetIndex == index }" v-for="(item, index) in presetDatas" :key="'preset_' + index" @click=";(presetIndex = index), getPresetLists()">{{ item.title }}</div>

										</div>

										<div class="lists scroll-display-none" v-if="presetData.length>0 && presetDatas[presetIndex].data.length && presetIndex!=3">
											<waterfall :col="3" :gutterWidth="4" :data="presetDatas[presetIndex].data">
												<div class="item" v-for="(item, index) in presetDatas[presetIndex].data" :key="'preset_item_' + index" >
													<div class="title" @click="promptInsertHtml(item.prompt)">{{ item.title }}
													<a v-if="presetIndex==1" style="float: right;font-size: 12px;" @click="presetClicksds(1, item.id, index)">撤回</a>
													<a v-if="presetIndex==0" style="float: right;font-size: 12px;" @click="tjrw(item.id)">完成任务</a>
													<a v-if="presetIndex==0" style="float: right;font-size: 12px;margin-right: 10px;" @click="yjrw(item.id)">任务移交</a>
													</div>
													<div class="text" @click="promptInsertHtml(item.prompt)">{{ item.desc }}</div>
													 <div class="text">{{item.createtime}}</div>
												</div>
											</waterfall>
										</div>
										<div   v-if="presetIndex==3">
											<a-tabs type="card" @change="callback" style="width: 100%;height: 45vh; overflow-y: auto;" >
											    <a-tab-pane key="1" tab="进行中">
														<a-collapse  >
														      <a-collapse-panel style="width: 100%;"  v-for="(item, index) in list_data1" :key="index" :header="item.title+'----'+item.real_name">
																<div>任务：{{item.prompt}}(
																	<span style="color:blue;" >进行中</span>
																)</div>
														        <div>接收人：{{item.department}}-{{item.job}}-{{item.real_name}}</div>
														        <div>{{item.createtime}}</div>
																<a v-if="presetIndex!=0" style="float: right;margin-top: -60px;color:red"  @click="presetClicksds(2, item.id, index)">撤销任务</a>

														      </a-collapse-panel>
														</a-collapse>
											    </a-tab-pane>
											    <a-tab-pane key="2" tab="待审阅">
													<a-collapse  >
													      <a-collapse-panel style="width: 100%;"  v-for="(item, index) in list_data1" :key="index" :header="item.title+'----'+item.real_name">
															<div>任务详情：{{item.prompt}}(
																<span style="color:blue;" >待审阅</span>
															)</div>
													        <div>接收人：{{item.department}}-{{item.job}}-{{item.real_name}}</div>
															<div>说明备注：{{item.beizhu}}</div>
															<div>任务附件：<a target="_blank" v-if="item.fujian" :href="item.fujian">查看附件</a></div>
													        <div>{{item.createtime}}</div>
															<a v-if="presetIndex!=0" style="float: right;margin-top: -80px;color:green;border:1px solid green;padding: 2px 10px;border-radius: 20px;"  @click="presetClicksds(3, item.id, 1)">通过</a>
															<a v-if="presetIndex!=0" style="float: right;margin-top: -40px;color:red;border:1px solid red;padding: 2px 10px;border-radius: 20px;"  @click="presetClicksds(3, item.id, 2)">驳回</a>
															<a v-if="presetIndex!=0" style="color:red"  @click="presetClicksds(2, item.id, index)">撤销任务</a>
													      </a-collapse-panel>
													</a-collapse>
											    </a-tab-pane>
											    <a-tab-pane key="3" tab="已完成">
											      <a-collapse  >
											           <a-collapse-panel style="width: 100%;"  v-for="(item, index) in list_data1" :key="index" :header="item.title+'----'+item.real_name">
											           		<div>任务：{{item.prompt}}(
											           		 <span style="color:green;" >已完成</span>
											           		)</div>
											             <div>接收人：{{item.department}}-{{item.job}}-{{item.real_name}}</div>
														 <div>说明备注：{{item.beizhu}}</div>
														 <div>任务附件：<a target="_blank" v-if="item.fujian" :href="item.fujian">查看附件</a></div>
											             <div>{{item.createtime}}</div>

											           </a-collapse-panel>
											      </a-collapse>
											    </a-tab-pane>
												<a-tab-pane key="4" tab="已取消">
												  <a-collapse  >
												       <a-collapse-panel style="width: 100%;"  v-for="(item, index) in list_data1" :key="index" :header="item.title+'----'+item.real_name">
												       	<div>任务：{{item.prompt}}(
												       	 <span style="color:red;" >任务取消</span>
												       	)</div>
												         <div>接收人：{{item.department}}-{{item.job}}-{{item.real_name}}</div>
												         <div>{{item.createtime}}</div>

												       </a-collapse-panel>
												  </a-collapse>
												</a-tab-pane>
											  </a-tabs>
										</div>
									</div>

								</div>
								
								<div class="list_box" :class="{ active: dItem.id == dialogId }" v-if="dItem.id == dialogId" v-for="(dItem, dIndex) in dialogList" :key="'dlist_' + dItem.id">
									<div class="list" v-for="(item, index) in dItem.list" :key="'msg_' + item.id">
										<div class="item right" v-if="item.message">
											<div class="message">
												<v-md-editor v-model="item.message" mode="preview"></v-md-editor>
												<div class="toolss">
													<div class="copy" @click="messageCopy(item.message)">
														<a-icon type="copy" />
														<span>复制</span>
													</div>
												</div>
											</div>
											<div class="avatar">
												<img :src="userInfo.avatar" alt="" />
											</div>
										</div>
										<div class="item left">
											<div class="avatar">
												<img :src="config.logo ? config.logo : require('@/assets/imgs/logo.png')" alt="" />
											</div>
											<div class="message" v-if="item.response">
												<div :id="'bom_'+item.id">
												<v-md-editor v-model="item.response" mode="preview" ref="bom"></v-md-editor>
												</div>
												<div class="tools" v-if="!item.transmit">
													<div class="copy" @click="messageCopy(item.response)">
														<a-icon type="copy" />
														<span>复制</span>
													</div>
													<div class="copy" style="margin-left: 12px;" @click="exportToWord(item.id)">
														<a-icon type="file-word" />
														<span>导出word</span>
													</div>
													<div class="copy" style="margin-left: 12px;" @click="speakText(item.response)">
														<a-icon type="sound" />
														<span>语音播放</span>
													</div>
													<div class="collect" v-if="item.message && item.id && item.id != 'message' && abortCheck(item.id)" @click="messageCollect(item)">
														<a-icon type="heart" :theme="item.vote == 0 ? 'outlined' : 'filled'" :style="{ color: item.vote == 0 ? '' : '#FF3434' }" />
														<span>收藏</span>
													</div>
													<div v-if="item.isHtml" class="copy" style="margin-left: 12px;" @click="runHtml(item.response)">
														<a-icon type="play-circle" />
														<span>运行HTML</span>
													</div>
													<div v-if="item.isHtml" class="copy" style="margin-left: 12px;" @click="exportToFile(item.response)">
														<a-icon type="save" />
														<span>保存为文件</span>
													</div>
													<div v-if="item.isHtml" class="copy" style="margin-left: 12px;" @click="shareHtml(item)">
														<a-icon type="share-alt" />
														<span>分享</span>
													</div>
												</div>
											</div>
											<div class="message" v-else>
												<a-icon type="loading" :style="{ fontSize: '26px' }" />
											</div>
										</div>
									</div>
								</div>
								<div class="suspend">
									<div class="icons" v-if="startTask" @click="abortRequestTask">
										<a-icon type="pause-circle" />
										<span>停止生成</span>
									</div>
								</div>
							</div>
							<div class="tools">
								<a-radio-group v-model="modeType" button-style="" size="small" v-if="qiyeban" >
									<a-radio-button :value="item.type" v-for="(item, index) in modeList" :key="'g_mode_' + index">模型:{{ item.name }}（{{ item.usable }}积分）</a-radio-button>
								</a-radio-group>
								<div v-if="!qiyeban">
								下发指令：<a-switch checked-children="是" un-checked-children="否"  @change="onChange" />
								</div>
								<a-select default-value="0" style="width:200px;margin-left: 20px;" @change="handleChange" >
															  <a-select-option value="0" >
															    选择关联知识库文档
															  </a-select-option>
								    <a-select-option :value="value.id"  v-for="(value,index) in history_data">
								      {{value.pdf_name}}
								    </a-select-option>
								  </a-select>
								<a href="/#/ai_pdf" style="margin-left:5px;">没有文档？去上传</a>
								<div class="clear c-pointer" v-if="dialogId != 0" @click="clearAloneChat">清空对话</div>
							</div>
							<div class="input">
								<div class="textarea">
									<div id="editable" ref="editable" class="prosemirror-editor scroll-display-none" contenteditable placeholder="请输入你的问题（Enter 发送消息 / Ctrl + Enter 换行）"></div>
								</div>
								 
								<div class="button">
									<button v-if="luyin" style="width: 100px;height: 30px;text-align: center;background-color: #fff;border-radius: 5px;border: 1px solid #ccc;"
									 @click="startRecognition"
									  ><a-icon type="audio" theme="twoTone"  />按住说话</button>
									  
									  <button v-else style="width: 100px;height: 30px;text-align: center;background-color: #fff;border-radius: 5px;border: 1px solid #ccc;"
									 @click="stopRecognition"
									    ><a-icon type="audio" theme="twoTone" two-tone-color="#eb2f96"  />结束录音</button>
									<a-button style="margin-left: 20px;" type="primary" size="small" :disabled="!disabled" @click="sendMessage">发送 </a-button>
								</div>
								<div class="clear" v-if="inputText" @click="clearHtml">
									<a-icon type="close-circle" theme="filled" />
								</div>
							</div>
						</div>
					</a-col>
				</a-card>
			</a-col>
		</a-row>	
		<a-modal
		                    v-model="modalShowAb"
		                    title="任务移交"
		                    @ok="rwhandleOk"
		                    @cancel="rehandleCancel"
		                    okText="确认移交"
		                    cancelText="取消"
		                >
						
		                    <a-select style="width: 100%;" @change="handleChange2" placeholder="选择人员">
		                        <a-select-option v-for="(item,index) in m_list" :value="item.user_id">
		                            {{item.name}}
		                        </a-select-option>
		                    </a-select>
		                   
		                </a-modal>
		<a-modal
		                    v-model="modalShowAbb"
		                    title="提交任务"
		                    @ok="rwhandleOks"
		                    @cancel="rehandleCancels"
		                    okText="确认移交"
		                    cancelText="取消"
		                >
		                   <a-upload
		                        name="file"
		                        :multiple="false"
		                        action="/addons/chatgpt/aiagent/rw_upload"
		                        :headers="headers"
		                        @change="handleChange21"
		                        accept=".pdf,.docx,.txt,.xlsx,.doc,.ppt,.jpg,.png,.jpeg,.xls,.pptx,.zip,.rar"
		                        class="pdf_upload_btn"
		                        :beforeUpload="beforeUpload"
		                    	:showUploadList="true"
		                    >
		                   <a-button> 
		                   <a-icon type="upload" /> 上传任务附件 </a-button>
		                   </a-upload>
		                    <a-input style="margin-top: 10px;" v-model="beizhu" type="textarea" placeholder="备注或说明" />
		</a-modal>
		<a-modal
      v-model="modalShowPreview"
      title="预览HTML"
      :footer="null"
      :width="800"
      :body-style="dialogStyle"
      @cancel="cancelPreview"
    >
       <iframe  :src="iframeUrl" style="border: none;width: 100%;height: 100%;">
           <p>您的浏览器不支持 iframe 标签,请从列表中下载预览</p>
       </iframe>
		</a-modal>
	</div>
	
</template>
<script setup>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	import htmlDocx from "html-docx-js/dist/html-docx";
	
	export default {
		components: {},
		data() {
			return {
				controller: null, // 数据流控制器
				inputText: "", // 发送数据
				editorContent: '',
				downloadLink:"",
				disabled: false, // 按钮状态
				dialogList: [], // 会话列表
				dialogId: 0, // 当前会话id
				createModal: false, // 创建会话标题弹窗
				createLoading: false, // 异步加载状态
				createTitle: "新建会话", // 新建会话标题
				modalType: "create", // create / edit
				editId: "", // 编辑标题ID
				modeList: [], // 模型列表
				modeType: "", // 选中模型
				presetData: [], // 预设分类
				presetDatas: [], // 预设分类
				presetIndex: 0, // 当前选择预设分类
				startTask: false, // 开始任务
				welcomeInfo: {}, // 欢迎语
				welcomeData: [], // 欢迎语数据
				newChat: false ,// 新对话
				pageHeight2:'600px',
				history_data:[],
				zsk_id:0,
				transcription: '', // 用于存储语音识别结果的变量
				recognition: null, // SpeechRecognition实例
				isRecording: false ,// 标识是否正在录音
				modalShowAb:false,
				rwid:0,
				m_list:[],
				rw_userid:0,
				modalShowAbb:false,
				trwid:0,
				beizhu:'',
				headers: {
				    authorization: 'authorization-text',
				    Token:'',
				},
				fujian:'',
				list_data1:[],
				list_data2:[],
				list_data3:[],
				keys:'',
				zhiling:false,
				luyin:true,
				qiyeban:true,
        // 预览相关
        modalShowPreview: false,
        iframeUrl: '',
			  dialogStyle: {
          height: '600px',
        }	
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo","qiyeban"]),
			...mapGetters("app", ["config"]),
		},
		watch: {
			inputText(newValue, oldValue) {
				this.disabled = newValue == "" ? false : true
			},
			token(newValue, oldValue) {
				newValue && this.getDialogList()
			}
		},
		created(){
			this.getUserInfo()
			this.token && this.getDialogList()
			!this.qiyeban && this.getPresetClassifys()
			this.getModeList()
			this.qiyeban && this.getPresetClassify()
			
			this.getPresetRandom()
			this.getUserPdf()
		},
		mounted() {
			
			this.watchWindowEvent()
			const pageHeight = document.documentElement.clientHeight;
			this. pageHeight2 = pageHeight -(window.screen.height-window.screen.availHeight);
			console.log("页面高度：" + this.pageHeight2);
		},
		methods: {
			...mapMutations("user", ["setLoginPopup"]),
			...mapActions("user", ["getUserInfo", "getStatisticInfo"]),
			 onChange(checked) {
				 this.zhiling = checked;
			    },
			renwu_list(id){
			   this.list_data1=[];
			   this.$http("role.get_renwu_user",{is:id}).then(res => {
			       if(res.code == 1){
			           this.list_data1 = res.data;
			       }
			   })
			},
			callback(key) {
				 console.log(key)
				 this.keys = key;
			      this.renwu_list(key)
			},
			beforeUpload(file,fileList) {// 上传前的限制 大小 类型
			    this.headers.Token = this.token;
			    const limitSize = file.size / 1024 / 1024 < 50;
			    if (!limitSize) {
			        this.$message.error(file.name + "文件大小不能超过50M")
			        return false;
			    }
			},
			handleChange21(info) {
			    if (info.file.status !== 'uploading') {
			       
			    }
			    if (info.file.status === 'done') {
			        if(info.file.response.code == 1){
						if(this.fujian){
							this.fujian = this.fujian+'|'+info.file.response.data;
						}else{
							this.fujian = info.file.response.data;
						}
						
			            this.$message.success(`${info.file.name} 文件上传成功`);
			        }else{
			            this.$message.error(info.file.response.msg);
			        }
			    } else if (info.file.status === 'error') {
			        this.$message.error(`${info.file.name} 文件上传失败.`);
			    }
			},
			// 终止检测
			abortCheck(id) {
				return String(id).indexOf("message_abort") == -1 ? true : false
			},
			handleChange(value){
				this.zsk_id = value;
			},
			yjrw(id){
				this.rwid = id;
				this.$http("role.get_department_user").then(res => {
				    if(res.code == 1){
				        this.m_list = res.data.user;
				    }
				})
				this.modalShowAb = true;
			},
			tjrw(id){
				this.trwid = id;
				this.modalShowAbb = true;
			},
			rehandleCancel(){
				this.modalShowAb = false;
			},
			rehandleCancels(){
				this.modalShowAbb = false;
			},
			rwhandleOk(){
				if(!this.rwid){
					alert('请选择任务ID');
					return;
				}
				if(!this.rw_userid){
					alert('请选择移交人');
					return;
				}
				this.$http("role.edit_rw_user",{id:this.rwid,new_user:this.rw_userid}).then(res => {
				    if(res.code == 1){
						this.presetDatas[this.presetIndex].data = [];
						this.getPresetLists();
				        this.$message.success("任务移交成功")
				        
				    }
				})
			},
			rwhandleOks(){
				if(!this.trwid){
					alert('请选择任务ID');
					return;
				}
				this.presetClicksd(this.trwid,this.beizhu,this.fujian);
				this.modalShowAbb = false;
			},
			handleChange2(value){
			    this.rw_userid = value;
			},
		    startRecognition() {
			
		      if (window.SpeechRecognition) {
		        this.recognition = new SpeechRecognition();
		      } else if (window.webkitSpeechRecognition) {
		        this.recognition = new webkitSpeechRecognition();
		      } else {
		        alert('您的浏览器不支持语音识别。');
		        return;
		      }
			  this.luyin = false;
			  this.inputText ="<span>语言输入中.....</span>"
			  this.$refs.editable.innerHTML = this.inputText 
		      this.recognition.interimResults = true; // 如果需要实时反馈，可以设置为true
		      this.recognition.lang = 'zh-CN'; // 设置识别的语言，这里是简体中文
		      this.recognition.onresult = event => {
		        this.transcription = ''; // 清空之前的转录文本
		        for (let i = event.resultIndex; i < event.results.length; i++) {
		          if (event.results[i].isFinal) { // 如果结果是最终的，则添加到转录文本中
		            this.transcription += event.results[i][0].transcript;
					this.inputText = "<span>"+this.transcription+"</span>"
					this.$refs.editable.innerHTML = this.inputText 
		          } else { // 如果结果不是最终的，也可以选择性地处理（例如，显示实时转录）
		            console.log(event.results[i][0].transcript); // 实时转录输出到控制台
		          }
		        }
		      };
		
		      this.recognition.onerror = event => {
		        console.error('Error in speech recognition:', event);
		      };
		
		      this.recognition.start(); // 开始识别
		    },
			stopRecognition() {
				 this.luyin = true;
				  if (this.recognition) {
					this.recognition.stop(); // 停止识别
				  }
			  },
			beforeDestroy() { // 在组件销毁前清理资源，防止内存泄漏。
				if (this.recognition) { this.recognition.stop(); } // 停止识别。如果不再需要它，停止它可以释放系统资源。
			  },
		
	        speakText(text) {
				 if (window.speechSynthesis) {
				        const utterance = new SpeechSynthesisUtterance(text);
				        utterance.lang = 'zh-CN'; // 设置语言为中文
				        utterance.volume = 1; // 设置音量，范围从0到1
				        utterance.rate = 1.7; // 设置语速，范围从0.1到10
				        window.speechSynthesis.speak(utterance);
				      } else {
				        alert('你的浏览器不支持语音合成');
				      }
			    },
			// 获取GPT模型
			getUserPdf(){
			    this.$http("role.getpdf").then(res => {
			        if(res.code == 1){
			            this.history_data = res.data;
			        }
			
			    })
			},
			// 监听window事件
			watchWindowEvent() {
				const that = this
				window.addEventListener("input", function (t) {
					const i = document.querySelector(".prosemirror-editor")
					if (i) {
						if (!i.innerText.replace(/^\s*[\r\n]/gm, "")) {
							that.inputText = ""
						} else {
							that.inputText = i.innerText.replace(/^\s*[\r\n]/gm, "")
						}
					}
				})
				window.addEventListener("click", function (t) {
					if (t.target.closest(".highlight-input")) {
						const i = document.querySelectorAll(".highlight-input")
						i.forEach(function (i) {
							i.contains(t.target) ? (t.target.parentElement.classList.add("hover-highlight-input"), t.target.parentElement.classList.add("active-highlight-input"), t.target.classList.add("active-highlight")) : (i.parentElement.classList.remove("hover-highlight-input"), i.parentElement.classList.remove("active-highlight-input"), i.classList.remove("active-highlight"))
						})
					} else if (!t.target.classList.contains("insert-prompt")) {
						let a = t.target.parentElement
						while (a) {
							if (a.classList.contains("insert-prompt")) break
							if (a === document.body) {
								const e = document.querySelectorAll(".highlight-input")
								e.forEach(function (t) {
									t.parentElement.classList.remove("hover-highlight-input")
									t.parentElement.classList.remove("active-highlight-input")
									t.classList.remove("active-highlight")
								})
								break
							}
							a = a.parentElement
						}
					}
				})
				window.addEventListener("keydown", function (t) {
					if (t.key === "Tab") {
						t.preventDefault()
						var i = document.querySelectorAll(".highlight-input"),
							a = window.getSelection().getRangeAt(0),
							e = -1
						if (
							(i.forEach(function (t, i) {
								t.parentElement.classList.remove("hover-highlight-input")
								t.parentElement.classList.remove("active-highlight-input")
								t.classList.remove("active-highlight")

								a.intersectsNode(t.parentElement) && (e = i)
							}),
							-1 !== e)
						) {
							var s = (e + 1) % i.length,
								n = i[s]

							n.parentElement.classList.add("hover-highlight-input")
							n.parentElement.classList.add("active-highlight-input")
							n.classList.add("active-highlight")

							var o = document.createRange()
							o.selectNodeContents(n), o.setStartAfter(n), o.collapse(!0)
							var l = window.getSelection()
							l.removeAllRanges(), l.addRange(o)
						} else {
							var f = document.querySelector(".highlight-input")
							if (f) {
								var r = document.createRange()
								r.selectNodeContents(f)

								f.parentElement.classList.add("hover-highlight-input")
								f.parentElement.classList.add("active-highlight-input")
								f.classList.add("active-highlight")

								var m = window.getSelection()
								m.removeAllRanges(), m.addRange(r)
							}
						}
					} else if ((t.ctrlKey || t.shiftKey || t.metaKey || t.altKey) && t.key === "Enter") {
						var c = document.querySelector(".prosemirror-editor")
						if (c) {
							var p = window.getSelection(),
								y = p.getRangeAt(0),
								h = document.createElement("span")

							h.textContent = "\n"
							y.deleteContents()
							y.insertNode(h)
							y.setStartAfter(h)
							y.collapse(!0)
							p.removeAllRanges()
							p.addRange(y)
						}
					} else if (t.key === "Enter") {
						t.preventDefault()
						that.sendMessage()
					} else if (t.key === "Backspace") {
						var S = window.getSelection(),
							C = S.getRangeAt(0),
							_ = C.commonAncestorContainer,
							w = 1 === _.nodeType ? _ : _.parentNode,
							z = w.previousElementSibling
						if (w && w.classList.contains("highlight-input")) {
							var N = window.getSelection(),
								k = N.toString()
							w.textContent === k && ((w.textContent = " "), t.preventDefault())
							var M = N.getRangeAt(0),
								T = M.startOffset,
								R = M.endOffset
							1 === T && 1 === R && t.preventDefault()
						}
						z && z.classList.contains("span-highlight-input") && z.parentNode.removeChild(z)
						var I = document.querySelectorAll(".highlight-input")
						if (w.classList.contains("span-highlight-input") && " " === w.childNodes[0].textContent) return void t.preventDefault()
						if (I && w.parentElement.classList.contains("span-highlight-input") && w.textContent && 1 === w.textContent.length) {
							t.preventDefault(), (w.textContent = " ")
							var P = document.createRange()
							P.setStartAfter(w), P.collapse(!0)
							var L = window.getSelection()
							L.removeAllRanges(), L.addRange(P)
						}
					}
				})
			},
			// 获取预设随机项
			getPresetRandom() {
				this.$http("chat.presetRandom", { limit: 3 }).then(res => {
					if (res.code === 1) {
						res.data?.welcome && (this.welcomeInfo = res.data.welcome)
						res.data?.list && res.data?.list.length && (this.welcomeData = res.data.list)
					}
				})
			},
			// 获取预设分类
			getPresetClassify() {
				this.$http("chat.presetClassify").then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
              let presetData = [{ id: 0, title: "全部", data: [] }]
							const arr = res.data.map(({ id, title }) => ({ id, title, data: [] }))
							this.presetData = presetData.concat(arr)
              this.presetIndex = 0
							this.getPresetList()
            }
            console.log('presetData =>', this.presetData)
					}
				})
			},
			// 获取预设列表
			getPresetList() {
				if (this.presetData[this.presetIndex].data.length) return
				this.$http("chat.presetList", { topic_id: this.presetData[this.presetIndex].id }).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							this.presetData[this.presetIndex].data = res.data
						}
					}
				})
			},
			// 预设点击量
			presetClicks(type,id,index) {
				this.$http("chat.presetDetail", {id}, false)
				type === 1 && this.presetData[this.presetIndex].data[index].usages++
			},
			// 获取任务分类
			getPresetClassifys() {
				this.$http("chat.presetClassifys").then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = res.data.map(({ id, title }) => ({ id, title, data: [] }))
							this.presetDatas = this.presetDatas.concat(arr)
							this.getPresetLists()
						}
					}
				})
			},
			// 获取任务列表
			getPresetLists() {
				if (this.presetDatas[this.presetIndex].data.length) return
				if (this.presetIndex!==3){
					this.$http("chat.presetLists", { topic_id: this.presetDatas[this.presetIndex].id }).then(res => {
						if (res.code === 1) {
							if (res.data && res.data.length) {
								this.presetDatas[this.presetIndex].data = res.data
							}
						}
					})
				}else{
					this.callback(1);
				}
			
			},
			// 提交任务
			presetClicksd(id, beizhu, fujian) {
				
				this.$http("chat.presetDetails", { id:id,beizhu:beizhu,fujian:fujian }, false).then(res => {
					if (res.code === 1) {
						this.presetDatas[this.presetIndex].data = [];
						this.getPresetLists();
						this.$message.success("任务已提交")
						
					}
				})
			},
			//撤回任务
		    presetClicksds(type, id, index) {
		    	this.$http("chat.presetDetails2", {type,id,index}, false).then(res => {
		    		if (res.code === 1) {
						if(this.presetIndex!=3){
							this.presetDatas[this.presetIndex].data = [];
							this.getPresetLists();
						}else{
							this.renwu_list(this.keys)
						}
						
		    			this.$message.success("任务状态已变更")
		    		}
		    	})
		    },
			// 清除Html
			clearHtml() {
				this.$refs.editable.innerHTML = ""
				this.inputText = ""
			},
			// 插入Html
			promptInsertHtml(text) {
				this.clearHtml()
				this.$nextTick(() => {
					const a = text.replace(/\[(.*?)\]/g, '<span class="span-highlight-input"><span contenteditable class="highlight-input">&nbsp;$1</span></span><span>&nbsp;</span>')
					const b = a.replace(/(.+?)\n/g, "<p>$1</p>")
					this.$refs.editable.innerHTML = b + "<span><br></span>"
					this.inputText = this.$refs.editable.innerText
					const o = document.querySelector(".highlight-input")
					if (o) {
						const l = document.createRange()
						l.selectNodeContents(o), l.setStartAfter(o), l.collapse(!0)
						const f = window.getSelection()
						f.removeAllRanges(), f.addRange(l), o.focus()

						setTimeout(function () {
							o.parentElement.classList.add("hover-highlight-input")
							o.parentElement.classList.add("active-highlight-input")
							o.classList.add("active-highlight")
						})
					}
				})
			},
			// 清除单个对话
			clearAloneChat() {
				const arr = this.dialogList.filter(item => item.transmit == true).map(item => item.id)
				if (arr.length) {
					this.$message.warning("请等待当前对话完成...")
					return
				}
				this.$confirm({
					title: "确定要清空当前对话吗？",
					content: "",
					okText: "清空",
					okType: "danger",
					cancelText: "取消",
					onOk: async () => {
						return await new Promise((resolve, reject) => {
							this.$http("chat.clearAlone", { type: "chat", group_id: this.dialogId }).then(res => {
								if (res.code === 1) {
									this.$message.success("清空成功")
									this.getChatHistory()
									resolve()
								} else {
									reject(res.msg)
								}
							})
						}).catch(error => {
							this.$message.error(error)
						})
					},
					onCancel() {}
				})
			},
			// 清空所有对话
			clearAllChat() {
				const arr = this.dialogList.filter(item => item.transmit == true).map(item => item.id)
				if (arr.length) {
					this.$message.warning("请等待当前对话完成...")
					return
				}
				this.$confirm({
					title: "确定要清空所有会话吗？",
					content: () => <div style="color: red;">清空后数据不可恢复!</div>,
					okText: "清空",
					okType: "danger",
					cancelText: "取消",
					onOk: async () => {
						return await new Promise((resolve, reject) => {
							this.$http("chat.clearAll", { type: "chat" }).then(res => {
								if (res.code === 1) {
									this.$message.success("清空成功")
									this.getDialogList()
									resolve()
								} else {
									reject(res.msg)
								}
							})
						}).catch(error => {
							this.$message.error(error)
						})
					},
					onCancel() {}
				})
			},
			// 获取GPT模型
			getModeList() {
        const data = { claude: "1" }
				this.$http("chat.mode", data).then(res => {
					if (res.code === 1) {
						this.modeList = res.data
						this.modeType = res.data[0].type
					}
				})
			},
			// 信息复制
			async messageCopy(text, info) {
        if (navigator.clipboard && window.isSecureContext) {
					await navigator.clipboard.writeText(text)
          if (info === undefined) {
					  this.$message.success("已复制到剪切板")
          } else {
					  this.$message.success(info)
          }
        } else {
          // 创建text area
          const textArea = document.createElement('textarea')
          textArea.value = text
          // 使text area不在viewport，同时设置不可见
          document.body.appendChild(textArea)
          textArea.focus()
          textArea.select()
          if (info === undefined) {
            this.$message.success('复制成功')
          } else {
            this.$message.success(info)
          }
          new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            document.execCommand('copy') ? res() : rej()
            textArea.remove()
          })
        }
			},
			// 导出为word
			exportToWord(id,name) {
			        const ood = 'bom_'+id
			        const content =document.getElementById(ood).innerHTML
			        const converted = htmlDocx.asBlob(content)
			        const url = window.URL.createObjectURL(converted)
					const a = document.createElement("a");
					a.href = url;
					a.download = '导出文件.docx';
					a.click();
					URL.revokeObjectURL(url);
			},
			// 导出为html
			exportToFile(text) {
        const arr = text.split("```html")
        const tmp = arr[1].split("```")
        let htmlStr = tmp[0]
        htmlStr = htmlStr.replace(/^\s+|\s+$/g,'')
        const blob = new Blob([htmlStr], {
          'type': 'text/html'
        });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a");
        a.href = url;
        a.download = 'zhd-preview.html';
        a.click();
        URL.revokeObjectURL(url);
			},
      // 运行
      runHtml(text) {
        const arr = text.split("```html")
        const tmp = arr[1].split("```")
        let htmlStr = tmp[0]
        htmlStr = htmlStr.replace(/^\s+|\s+$/g,'')
        console.log(htmlStr)
        const blob = new Blob([htmlStr], {
          'type': 'text/html'
        });
        console.log('blob =>', blob)
        const iframeUrl = window.URL.createObjectURL(blob)
        this.modalShowPreview = true
        this.iframeUrl = iframeUrl
      },
      // 分享
      async shareHtml(item) {
        console.log('share item=>', item)
				const res = await this.$http("chat.share", { id: item.id })
        if (res.code === 1) {
          const shareUrl = res.data.share_url;
          await this.messageCopy(shareUrl, "已复制分享链接到剪切板")
        } else {
          this.$message.error(res.msg)
        }
      },
      cancelPreview() {
        this.modalShowPreview = false
        this.iframeUrl = null
        console.log('1111')
      },
			// 信息收藏
			messageCollect(item) {
				if (!item.id || item.id == "message") return
				this.$http("chat.collect", { type: "chat", msg_id: item.id }).then(res => {
					if (res.code === 1) {
						item.vote = item.vote == 0 ? 1 : 0
						this.$message.success(res.msg)
					}
				})
			},
			// 新建会话标题
			createOkHandle() {
				if (!this.createTitle) {
					this.$message.warning("请输入会话标题")
					return
				}
				const obj = { id: this.editId, title: this.createTitle }
				this.createLoading = true
				this.$http("chat.create", obj).then(res => {
					if (res.code === 1) {
						this.dialogId = res.data.group_id
						this.getDialogList()
						!this.newChat && this.$message.success(res.msg)
					} else {
						this.$message.error(res.msg)
					}
					this.createModal = false
					this.createLoading = false
				})
			},
			// 创建/修改 会话
			createDialog(type, id, title) {
				const arr = this.dialogList.filter(item => item.transmit == true).map(item => item.id)
				if (arr.length) {
					this.$message.warning("请等待当前对话完成...")
					return
				}
				if (type === "create") {
					this.editId = ""
					this.createTitle = ""
					this.dialogId = 0
					this.$message.info("已切换最新对话")
				} else if (type === "edit") {
					this.editId = id
					this.createTitle = title
					this.modalType = type
					this.createModal = true
				} else {
					console.log("未知类型 => 已拦截")
					return
				}
			},
			// 删除会话
			deleteDialog(id) {
				this.$confirm({
					title: "确定要删除当前对话吗？",
					content: "",
					okText: "删除",
					okType: "danger",
					cancelText: "取消",
					onOk: async () => {
						return await new Promise((resolve, reject) => {
							this.$http("chat.delete", { id }).then(res => {
								if (res.code === 1) {
									this.dialogId == id && (this.dialogId = 0)
									this.getDialogList()
									resolve()
								} else {
									reject(res.msg)
								}
							})
						}).catch(error => {
							this.$message.error(error)
						})
					},
					onCancel() {}
				})
				return
			},
			// 切换会话项
			dialogItem(id) {
				this.dialogId = id
				this.dialogList.map(item => {
					if (item.id == id) {
						if (!item.transmit && !item.list.length) this.getChatHistory()
					}
				})
			},
			// 会话列表
			getDialogList() {
				this.$http("chat.list").then(res => {
					if (res.code === 1) {
						res.data.map((item, index) => {
							item.list = []
							item.transmit = false
						})
						this.dialogList = res.data
						this.getChatHistory()
					}
				})
			},
			// 历史记录
			getChatHistory() {
				this.$http("chat.history", { group_id: this.dialogId }).then(res => {
					if (res.code === 1) {
						this.dialogList.map(item => {
							if (item.id == this.dialogId) {
                const msglist = res.data.message_list.reverse()
                console.log('msglist =>', msglist)
                for(let i in msglist) {
                  let response = msglist[i].response
                  let tmp = response.split("```html")
                  msglist[i].isHtml = false
                  // 判断是否为HTML
                  if (tmp.length > 1) {
									  msglist[i].isHtml = true
                  }
                }
								item.list = msglist 
								item.msg_total = res.data.message_count
							}
						})
						this.newChat && this.fetchDataStream(this.inputText, this.dialogId, this.modeType)
					}
				})
			},
			// 发送内容
			sendMessage() {
				if (!this.token) return this.setLoginPopup(true)
				if (this.dialogId == 0) {
					if (!this.inputText) {
						console.log("输入为空")
						return
					}
					if (!this.modeType) {
						this.$message.error("会话模型错误，请联系管理员")
						return
					}
					this.$refs.editable.innerHTML = ""
					this.createTitle = this.inputText
					this.newChat = true
					this.createOkHandle()
				} else {
					this.dialogList.map(item => {
						if (item.id == this.dialogId) {
							if (item.transmit) {
								this.$message.warning("请等待当前对话完成...")
							} else {
								if (!this.inputText) {
									console.log("输入为空")
									return
								}
								if (!this.modeType) {
									this.$message.error("会话模型错误，请联系管理员")
									return
								}
								this.fetchDataStream(this.inputText, this.dialogId, this.modeType)
							}
						}
					})
				}
			},
			// 终止请求任务
			abortRequestTask() {
				if (this.controller) {
					this.controller.abort()
					this.controller = null
					this.dialogList.map(item => {
						if (item.id == this.dialogId) {
							item.transmit = false
							item.list[0].transmit = null
							item.list[0].id = "message_abort_" + Date.now()
							this.startTask = false
						}
					})
				}
			},
			// 发送请求
			async fetchDataStream(message, dId, mode) {
				if (!message) {
					console.log("输入为空")
					return
				}
				this.clearHtml()
				this.dialogList.map(item => {
					if (item.id == dId) {
						item.list.unshift({
							id: "message",
							message,
							response: "",
							transmit: true,
							vote: 0
						})
						item.transmit = true
						item.msg_total = Number(item.msg_total) + 1
					}
				})
				this.startTask = true
				this.disabled = true
				this.newChat = false
				const postData = { type: "chat", message, group_id: dId, mode,zskid:this.zsk_id,zhiling:this.zhiling},
					url = this.$BASE_API + "/addons/chatgpt/web/sendText",
					Token = this.token,
					Sign = window.location.search.replace(/\?/g, "")
				this.controller = new AbortController()

				try {
					this.dialogList.map(item => {
						if (item.id == dId) {
							item.list[0].response = ""
						}
					})

					const response = await fetch(url, {
						method: "post",
						headers: {
							"Content-Type": "application/json;charset=utf-8",
							Token,
							Sign
						},
						body: JSON.stringify(postData),
						signal: this.controller.signal
					})

					const reader = response.body.getReader()
					let data = ""

					while (true) {
						const { done, value } = await reader.read(),
							str = new TextDecoder().decode(value)
						if (str.indexOf("data: [DONE]") != -1 || str.indexOf("data:[DONE]") != -1 || done) {
							const arr = str.replaceAll(" ", "").split("data:[DONE]")
							if (arr[0].length) {
								this.dialogList.map(item => {
									if (item.id == dId) {
										item.list[0].response += arr[0]
									}
								})
							}
							this.dialogList.map(item => {
								if (item.id == dId) {
									item.transmit = false
									item.list[0].transmit = null
									item.list[0].id = arr[1]
									item.list[0].isHtml = false
                  const tmp = item.list[0].response.split("```html")
                  // 判断是否为HTML
                  if (tmp.length > 1) {
									  item.list[0].isHtml = true
                  }

									this.startTask = false
                  console.log("response =>" , item.list[0])
								}
							})
							break
						}

						data += str
						this.dialogList.map(item => {
							if (item.id == dId) {
								item.list[0].response = data
							}
						})
					}
          console.log('dialogList =>', this.dialogList)
				} catch {
					console.error("请求失败")
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page_container {
		// height: 850px;
		min-height: calc(100% - 24px);
		display: flex;
		margin-bottom: 24px;

		border-radius: 12px;
		box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
	}
	.dialog_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background: #05abf508;
		padding: 12px 0;

		.head {
			text-align: center;
			padding: 12px;
			background: #fff;
			border-radius: 10px;
			margin: 0 12px;
			cursor: pointer;
			color: #000;
			font-weight: bold;
			box-shadow: 1px 1px 10px 0 rgba(#000, 0.05);
		}

		.list {
			flex: 1;
			height: 100%;
			padding: 12px;
			overflow: hidden;
			margin-top: 12px;

			&:hover {
				overflow-y: scroll;
				overflow-x: hidden;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 4px solid rgba(0, 0, 0, 0);
				box-shadow: 4px 0 0 #a5adb7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 4px 0 0 #4a4a4a inset;
			}

			// 滚动条两端按钮
			&::-webkit-scrollbar-button {
				height: 10px;
			}

			.item {
				padding: 20px 12px;
				background: #fff;
				border-radius: 10px;
				margin-bottom: 12px;
				cursor: pointer;
				position: relative;

				&:last-child {
					margin-bottom: 0;
				}

				&.active {
					border: 1px solid #1890ff;
					box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
				}

				&:hover {
					.delete {
						display: block;
					}
				}

				.edit {
					position: absolute;
					top: 4px;
					right: 10px;
					color: #1890ff;
					font-size: 12px;
					font-weight: 700;

					&:hover {
						text-shadow: 1px 1px 3px rgba(#000, 0.5);
					}
				}

				.line1 {
					color: #000;
					font-weight: 700;
				}

				.line2 {
					display: flex;
					align-items: center;
					justify-content: space-between;
					white-space: nowrap;
					flex-wrap: wrap;
					margin-top: 4px;
					color: #999;
				}

				.delete {
					position: absolute;
					top: 0;
					left: 0;
					transform: translate(-30%, -30%);
					background: #fff;
					border-radius: 50%;
					overflow: hidden;
					display: none;
				}
			}
		}

		.foot {
			margin-top: 10px;
			text-align: center;

			span {
				color: #1890ff;
				cursor: pointer;
				font-size: 14px;
			}
		}
	}
	.content_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;

		// 滚动条整体
		::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}
		// 滚动条滑块
		::-webkit-scrollbar-thumb {
			background-clip: padding-box;
			background-color: #a5adb7;
			border: 1px dashed rgba(0, 0, 0, 0);
			border-radius: 6px;
		}
		// 滚动条滑块 hover
		::-webkit-scrollbar-thumb:hover {
			background: #4a4a4a;
		}
		// 滚动条轨道
		::-webkit-scrollbar-track {
			background-color: transparent;
		}
		// 滚动条两端按钮
		::-webkit-scrollbar-button {
			height: 10px;
		}

		.output {
			flex: 1;
			border-radius: 12px;
			// background: #f7f7f7;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			position: relative;

			.welcome {
				padding: 10px 20px;
				color: #000;
				font-size: 16px;
				.p1 {
					font-weight: 700;
				}
				.p2 {
					margin-top: 10px;
				}
				.head {
					// margin-bottom: 20px;
					.title {
						font-size: 24px;
						font-weight: 700;
					}
				}
				.lists {
					// margin-top: 20px;
					.item {
						margin-bottom: 14px;
						margin-right: 10px;
						.p2 {
							color: #1890ff;
							cursor: pointer;
							&:hover {
								text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
							}
						}
					}
				}
			}

			.list_box {
				overflow-y: auto;
				overflow-x: hidden;
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				z-index: 1000;
				padding: 20px;
				margin: 0 6px;

				.list {
					margin-bottom: 20px;

					.item {
						margin-bottom: 20px;
						display: flex;
						flex-wrap: nowrap;
						$imgsize: 40px;

						.avatar {
							width: $imgsize;
							height: $imgsize;
							border-radius: 50%;
							overflow: hidden;
							border: 2px solid #fff;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.message {
							min-width: 0;
							max-width: calc(100% - #{$imgsize + 20px} * 2);
							padding: 10px 12px;

							::v-deep .v-md-editor__right-area {
								width: auto;
								min-width: 0;
								max-width: 100%;
								overflow: hidden;

								.scrollbar__bar.is-vertical {
									display: none;
								}
							}

							::v-deep .vuepress-markdown-body {
								padding: 0;
							}
						}

						&.left {
							justify-content: flex-start;

							.avatar {
								margin-right: 20px;
							}

							.message {
								border-radius: 0 12px 12px 12px;
								background: #fff;
								color: #000;
								position: relative;

								&:hover {
									.tools {
										display: flex;
									}
								}

								.tools {
									position: absolute;
									bottom: 0;
									left: 0;
									transform: translateY(90%);
									padding-top: 10px;
									display: flex;
									align-items: center;
									display: none;

									.copy,
									.collect {
										padding: 4px 10px;
										border: 1px solid #eee;
										border-radius: 6px;
										cursor: pointer;
										white-space: nowrap;

										&:hover {
											box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
											background: #fff;
										}

										span {
											margin-left: 4px;
										}
									}

									.collect {
										margin-left: 12px;
									}
								}
							}
						}

						&.right {
							justify-content: flex-end;

							.avatar {
								margin-left: 20px;
							}

							.message {
								border-radius: 12px 0 12px 12px;
								background: $message_color;
								color: #000;
							    margin-bottom:20px;
									
								&:hover {
									.toolss {
										display: flex;
									}
								}
								
								.toolss {
									// color: #000;
									position: absolute;
									// bottom: 0;
									 right: 82px;
									// transform: translateY(90%);
									margin-top: -22px;
									display: flex;
									align-items: center;
									 display: none;
									 
								
									.copy,
									.collect {
										 background-color: rgba(#fff, 0.5);
										color: #000;
										padding: 4px 10px;
										border: 1px solid #eee;
										border-radius: 6px;
										cursor: pointer;
										white-space: nowrap;
								        margin-top:33px;
										&:hover {
											box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
											// background: #1890ff;
											// color:#fff
										}
								
										span {
											margin-left: 4px;
										}
									}
								
									.collect {
										margin-left: 12px;
									}
								}
								::v-deep .vuepress-markdown-body {
									 background: $message_color;
								color: #000;}
								
							}
						}
					}
				}
			}

			.suspend {
				position: absolute;
				bottom: 10px;
				left: 50%;
				z-index: 1000;
				transform: translateX(-50%);
				display: flex;
				align-items: center;
				justify-content: center;

				.icons {
					padding: 4px 10px;
					background: #fff;
					border-radius: 6px;
					display: flex;
					align-items: center;
					font-size: 14px;
					cursor: pointer;
					color: #333;

					&:hover {
						color: #1890ff;
					}

					span {
						margin-left: 6px;
					}
				}
			}
		}
		.tools {
			padding: 10px 0;
			position: relative;
			display: flex;
			align-items: center;

			.clear {
				position: absolute;
				top: 50%;
				right: 2px;
				transform: translateY(-50%);
				font-size: 14px;
				color: #1890ff;
			}
		}
		.input {
			min-height: 120px;
			max-height: 200px;
			position: relative;
			// background: #f7f7f7;
			border-radius: 12px;
			border:1px solid #000;
			padding: 12px 84px 12px 12px;

			.textarea {
				width: 100%;
				height: 100%;
				border: none;
				outline: none;
				resize: none;
				background: transparent;
				padding: 0;
				margin: 0;
			}

			.button {
				position: absolute;
				bottom: 12px;
				right: 12px;
			}

			.clear {
				position: absolute;
				right: 52px;
				top: 18px;
				font-size: 20px;
				display: flex;
				cursor: pointer;
				color: #999;

				&:hover {
					color: #000;
				}
			}
		}

		::v-deep .prosemirror-editor {
			width: 100%;
			height: 100%;
			padding: 5px;
			color: #1a2029;
			text-align: left;
			line-height: 24px;
			overflow-y: auto;
			outline: none;
			font-size: 14px;
			word-break: break-word;
			white-space: pre-wrap;

			&:empty:before {
				content: attr(placeholder);
				color: #bbb;
			}

			.span-highlight-input {
				outline: none;
				font-size: 14px;
				word-break: break-word;
				white-space: pre-wrap;
				margin-left: 5px;
				padding: 3px;
				border-radius: 4px;
				box-shadow: none;
				outline: 1px solid transparent;

				&:hover .highlight-input {
					color: #1a2029;
					border-radius: 4px;
					background: #c8deff !important;
				}
			}

			.hover-highlight-input {
				&:hover .highlight-input {
					color: #1a2029;
					border-radius: 4px;
					background: #fff !important;
				}
			}

			.active-highlight-input {
				outline: 1px solid rgb(36, 84, 255);
			}

			.highlight-input {
				caret-color: $theme_color;
				font-size: 14px;
				padding: 2px 8px 2px 4px;
				background: rgba(36, 84, 255, 0.1);
				border-radius: 4px;
				color: #4f5866;
				cursor: pointer;
			}

			.active-highlight {
				background: #fff;
				border-radius: 0;
				color: #1a2029;
				cursor: text;
			}
		}
	}

	.classify_box {
		flex-shrink: 0;
		// width: 410px;
		width:100%;
		height: 50vh;
		// background: #f7f7f7;
		border-radius: 12px;
		// padding: 12px;
		// margin: 16px 16px 16px 0;
		display: flex;
		flex-direction: column;
		// overflow: scroll;

		.heads {
			font-size: 20px;
			font-weight: 700;
			color: #000;
		}

		.tabs {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 10px 0;

			.item {
				font-size: 14px;
				border-radius: 8px;
				padding: 4px 16px;
				margin-right: 10px;
				cursor: pointer;
				background: #f6fbff;
				margin-bottom: 10px;

				&.active {
					background: #1890ff;
					color: #fff;
					font-weight: 700;

					&:hover {
						background: #1890ff;
						color: #fff;
					}
				}

				&:hover {
					background: rgba(#1890ff, 0.1);
					color: #1890ff;
				}
			}
		}

		.lists {
			flex: 1;
			overflow-y: auto;
            height: 220px;
			.item {
				border-radius: 8px;
				padding: 20px;
				background: rgba(#1890ff, 0.04);
				cursor: pointer;
				margin-bottom: 10px;
				&:hover {
					background: url('../assets/imgs/ggmsg.jpg') 0px 0px / cover no-repeat;;
				}
				.title {
					font-size: 18px;
					font-weight: 500;
				}
				.text {
					text-overflow: ellipsis;
					overflow: hidden;
                    white-space: nowrap;
					font-size: 13px;
					font-weight: 400;
					color: #676c90;
					margin: 4px 0;
				}
				.info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					color: #999;

					.left {
						display: flex;
						align-items: center;
						span {
							margin-left: 4px;
						}
					}

					.right {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 24px;
						height: 24px;
						border-radius: 4px;
						transition: transform 0.1s;
						&:hover {
							background: rgba(#000, 0.1);
							transform: scale(1.1);
							color: #000;
						}
					}
				}
			}
		}
	}
</style>
